import React from "react";
import { Container, Row, Col} from 'react-bootstrap';
export default function Two(){

    return (
        <>
            <Container fluid className="bg-white">
                <Container>
                    <Row className="fade-in py-5">
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className="__text-desc_plc">
                                <p className="text-center text-dark">
                                    Situs ini bukan bagian dari Facebook atau Facebook Inc. Selain itu, situs ini tidak berkaitan sama sekali dengan situs Facebook dalam hal apapun. FACEBOOK adalah merek dagang dari FACEBOOK, Inc. <br/><br/>

                                    This site is not a part of the Facebook website or Facebook Inc. Additionally, this site is not endorsed by Facebook in any way. FACEBOOK is a trademark of FACEBOOK, Inc.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}